/* Ensure App has padding to avoid overlap issues */
.App{
  position: relative;
  transition: all 0.3s cubic-bezier(0.65, 0, 0.35, 1); 
  background-color: rgb(255, 246, 235); /* Fallback background color */
  /* background-image: url('./2022594.svg'); */
  
  
}



.App.dark {
  background: #121212; /* Very dark background for container */
  /* background-image: url('./1143502.svg'); */
}

/* Style for the theme toggle button */
.theme-toggle {
  position: fixed;
  z-index: 5000;
  transition: all 0.3s cubic-bezier(0.65, 0, 0.35, 1); /* Adjusted transition time */
}

.theme-toggle button {
  background-color: transparent;
  color: #1e4902;
  border: none;
  font-size: 1.5rem; /* Increase icon size */
  cursor: pointer;
  transform: scale(1);
  transition: all 3s cubic-bezier(0.65, 0, 0.35, 1); 
 
}
.theme-toggle:active{
    color: #fab209;
  transition: all 8s cubic-bezier(0.65, 0, 0.35, 1); 
}

.theme-toggle button:hover {
  color: #ff6528; /* Highlight color on hover */
  text-shadow:0 0 100px #00000000  ;
  transition: all 8s cubic-bezier(0.65, 0, 0.35, 1);
  }

/* Responsive styling */
@media (max-width: 1165px) {
  /* Mobile adjustments */
  .theme-toggle {
    top: 10px; /* Move up a little for smaller screens */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Ensures it's centered */
    transition: all 8s cubic-bezier(0.65, 0, 0.35, 1);
  }

  .theme-toggle button {
    padding: 8px; /* Smaller padding on mobile */
    font-size: 1.2rem; /* Adjust icon size */
    transition: all 8s cubic-bezier(0.65, 0, 0.35, 1);
  }
}

/* Ensure loading spinner is centered */
.loading-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full height to center the icon */
  
}

.loading-icon img {
  width: 100px; /* Adjust the size of the loading logo */
  animation: spin 2s linear infinite; /* Simple spinning animation */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Additional global styles */
a {
  color: inherit;
  text-decoration: none;
  transition: color 0.3s;
}

/* Base styles for the theme toggle button */
.theme-toggle {
  position: fixed;
  z-index: 5000;

}

/* Position for PC */
@media (min-width: 1165px) {
  .theme-toggle {
    top: 20px; /* Distance from top edge */
    left: 20px; /* Distance from left edge */
  }
}

/* Position for mobile devices */
@media (max-width: 1165px) {
  .theme-toggle {
    top: 10px; /* Distance from top edge */
    left: 50%;
    transform: translateX(-50%); /* Center horizontally */
  }
}
